.table-Result{
  background-color:#428bca8c;
    color: white;
    text-align: justify;
    border-radius: 16px;
    font-size: small;
}


/* using this place for global changes */
.login-agreements-input {
  /* display: flex !important; */
  align-items: center !important;
  justify-content: flex-start !important;
}
/* .login-agreements-input input {
  margin-top: -1.5rem;
} */